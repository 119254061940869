<template>
<div v-if="$apollo.loading"></div>
<div v-else>
    <div class="px-lg-16 secondary">
        <v-breadcrumbs :items="breadcrumbs" :dark="getDarkMode('secondary')" class="secondary">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
    </div>
    <v-container class="px-md-16">
        <v-card flat tile>
            <nice-header>
                {{ orderLabel }} {{ order.hashId }}
                <template v-slot:extra v-if="order.status === 'UNCONFIRMED'">
                    <v-spacer></v-spacer>
                    <manual-checkout :order="order"/>
                </template>
                <template v-slot:extra v-else-if="!order.reviewSubmitted && order.reviewsEnabled">
                    <v-spacer></v-spacer>
                    <review-dialog @submit="$apollo.queries.order.refetch()" :order="order"></review-dialog>
                </template>
            </nice-header>

            <v-alert type="info" v-if="order.status === 'UNCONFIRMED'" outlined>
                This is a quote from {{ store.name }}. To accept it and place the order, use the button above
            </v-alert>
            <v-alert
                type="info"
                prominent
                v-if="order.deposit && !order.deposit.paymentMethod"
            >
                <v-row align="center">
                    <v-col class="grow">
                        {{ store.name }} has requested a security deposit of {{ order.currency.symbol }} {{ order.deposit.amount }}
                        for this rental. A temporary hold will be placed on your card for this value when your rental starts,
                        and will be released on the safe return of the rented equipment.
                    </v-col>
                    <v-col class="shrink">
                        <btn
                                color="accent"
                                class="no-transform"
                                @click="payDeposit"
                        >
                            Add card details
                        </btn>
                    </v-col>
                </v-row>
            </v-alert>
            <v-alert type="info" outlined v-if="order.status === 'PENDING'">
                Your order status is currently pending. A member of our team will confirm the order shortly
            </v-alert>
            <v-alert type="error" outlined v-if="order.status === 'CANCELLED'">
                This order has been cancelled
            </v-alert>
            <v-row>
                <v-col cols="12" md="6">
                    <v-list two-line>
                        <list-item
                                :title="order.hashId"
                                :subtitle="`${orderLabel} Number`"
                                icon="mdi-numeric"
                        />
                        <list-item
                                :title="order.status"
                                :subtitle="`${orderLabel} Status`"
                                icon="mdi-list-status"
                        />
                    </v-list>
                </v-col>
                <v-col cols="12" md="6">
                    <v-list two-line>
                        <list-item
                                :title="order.hireType.toLowerCase() === 'collection' ? store.address : order.deliveryAddress"
                                :subtitle="order.hireType.toLowerCase() === 'collection' ? 'Collection address' : 'Delivery address'"
                                icon="mdi-store"
                        />
                        <list-item
                            :title="`${order.customer.name} (${order.customer.email})`"
                            subtitle="Customer"
                            icon="mdi-account-circle"
                        />
                    </v-list>
                </v-col>
            </v-row>

            <template v-for="(item, i) in order.lineItems">
                <div :key="i">
                    <v-container>
                        <h4>
                            Starts {{ item.startTime | niceTime }}. Ends {{ item.endTime | niceTime }}
                        </h4>
                    </v-container>

                    <v-simple-table>
                            <thead>
                            <tr>
                                <th class="text-left title">Product</th>
                                <th class="text-left title">Category</th>
                                <th class="text-left title">{{store.labelOne}}</th>
                                <th class="text-left title">{{store.labelTwo}}</th>
                                <th class="text-left title">{{store.labelThree}}</th>
                                <th class="text-left title">Quantity</th>
                                <th class="text-left title">Subtotal</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="subItem in item.lineItems" :key="subItem.id">
                                <td>
                                    {{ subItem.plan.product.name }}
                                </td>

                                <td>
                                    {{subItem.plan.product.category.name}}
                                </td>
                                <td>
                                    {{ subItem.assets[0].size }}
                                </td>
                                <td>
                                    {{ subItem.assets[0].gender }}
                                </td>
                                <td>
                                    {{ subItem.assets[0].brand }}
                                </td>

                                <td>
                                    <div class="py-5">
                                        <div>
                                            {{ subItem.assets.length }} item for {{ subItem.plan.interval * subItem.quantity }} {{ subItem.plan.period.toLowerCase() }}<span v-if="subItem.plan.interval * subItem.quantity > 1">s</span></div>
                                        <div class="grey--text">
                                            {{ store.currency.symbol }} {{subItem.plan.price  * subItem.quantity }} per item
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    {{ subItem.subtotal }}
                                </td>
                            </tr>
                            </tbody>
                    </v-simple-table>

                    <v-divider class="mb-15"></v-divider>
                </div>
            </template>
        </v-card>
    </v-container>


    <v-container>
        <v-row class="justify-center align-center">
            <v-col cols="6" v-if="order.extras.length > 0" offset="3">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left title">Description</th>
                            <th class="text-left title">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="extra in order.extras" :key="extra.id">
                            <td>{{extra.description}}</td>
                            <td>{{order.currency.symbol}}{{extra.value}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="2">
                <h4>Grand total: {{ order.grandTotal }}</h4>
                <h5 
                    class="font-weight-normal" 
                    v-if="order.billingPercentage < 100"
                >Paid to date: {{order.paidToDate }}</h5>
                <h5 
                    class="font-weight-normal" 
                    v-if="order.remainingBalance"
                >Remaining Balance: {{order.remainingBalance }}</h5>
            </v-col>
        </v-row>

    </v-container>

</div>
</template>

<script>
    import {store, order} from '@/graphql'
    import moment from 'moment'
    import { mapState, mapGetters } from 'vuex'
    import Cookie from 'js-cookie'

    export default {
        apollo: {
            store,
            order: {
                query: order,
                variables() {
                    return {
                        store: this.storeHash,
                        token: this.$route.params.accessToken
                    }
                }
            }
        },
        mounted () {
            Cookie.remove('basket');
        },
        filters: {
            duration(plan) {
                return `${plan.interval} ${plan.period}`
            },
            niceTime(val) {
                return moment.parseZone(val).calendar()
            }
        },
        methods: {
            payDeposit() {
                const {stripeAccount, publishableKey, sessionId} = this.order.deposit;
                // eslint-disable-next-line
                var stripe = Stripe(publishableKey, {
                    stripeAccount
                });
                stripe.redirectToCheckout({
                    sessionId
                })
            }
        },
        computed: {
            ...mapState(['store']),
            ...mapGetters(['getDarkMode']),
            orderLabel () {
                if (this.order.status === 'UNCONFIRMED') {
                    return 'Quote'
                }
                return 'Order'
            },
            breadcrumbs() {
                let crumbs = [
                    {
                        text: this.store.name,
                        href: '/'
                    }];
                if (this.order) {
                    crumbs.push({
                        text: `Order ${this.order.hashId}`,
                        disabled: true
                    })
                }
                return crumbs
            }
        }
    }

</script>


<style scoped>
.no-transform {
  font-size: 16px;
  text-transform: none !important;
}
</style>
